import Contentfilterbox from '../../components/Contentfilterbox/Contentfilterbox';
import React, { useEffect, useState } from 'react';

import Coursesfilterbox from '../../components/Coursesfilterbox';
import ReactPaginate from 'react-paginate';
import { api, getUserName, IBLSpinLoader } from '@iblai/ibl-web-react-common';
import {
  getDiscoverItemsAPI,
  getFacetItemsAPI,
  getQueryParamValue,
  getTenants,
} from '../../hooks/helpers';
import useDiscover from '../../hooks/useDiscover';
import FilterTags from '../../components/FilterTags/FilterTags';
import { useTranslation } from 'react-i18next';

const BrowseContentView = () => {
  const [
    discoverItems,
    discoverLoaded,
    setDiscoverItems,
    setDiscoverLoaded,
    selectedTerms,
    setSelectedTerms,
    totalPages,
    setTotalPages,
  ] = useDiscover();
  const [currentPage, setCurrentPage] = useState(1);
  const [contentFilterBoxItems, setContentFilterBoxItems] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    getFacetItemsAPI(setContentFilterBoxItems);
  }, []);

  const discoverCallback = (data) => {
    setDiscoverItems(data);
    setDiscoverLoaded(true);
  };
  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
    const requestData = {
      limit: 12,
    };
    if (selected + 1 > 1) {
      requestData['offset'] = selected * 12;
    }
    const username = getUserName();
    if (username) {
      requestData['username'] = username;
      api.ibldmsearch.searchPersonalizedCatalog(requestData, (_data) => {
        setTotalPages(_data.total_pages);
        discoverCallback(extractCourses(_data.results));
      });
    } else {
      api.ibldmsearch.searchCatalog(requestData, (_data) => {
        setTotalPages(_data.total_pages);
        discoverCallback(extractCourses(_data.results));
      });
    }
  };

  const extractCourses = (results) => {
    return results.map((result) => ({ ...result.data, type: result.type }));
  };

  const facetHandler = (newSelectedTerms) => {
    const requestData = {};
    console.log('############### newSelectedTerms ', newSelectedTerms);
    newSelectedTerms.forEach((_term) => {
      const [term, value] = _term.split(':');
      console.log('######### term ', term, '####### value ', value);
      if (requestData.hasOwnProperty(term)) {
        if (Array.isArray(requestData[term])) {
          requestData[term].push(value);
        } else {
          requestData[term] = [requestData[term], value];
        }
      } else {
        requestData[term] = value;
      }
    });
    console.log('############## requestData', { ...requestData });
    setCurrentPage(1);
    requestData['limit'] = 12;
    const username = getUserName();
    if (username) {
      // const tenants = getTenants().join(',');
      // requestData['tenant'] = tenants;
      requestData['username'] = username;
      api.ibldmsearch.searchPersonalizedCatalog(requestData, (_data) => {
        discoverCallback(extractCourses(_data.results));
      });
    } else {
      api.ibldmsearch.searchCatalog({ ...requestData }, (_data) => {
        discoverCallback(extractCourses(_data.results));
      });
    }

    // getDiscoverItemsAPI(
    //   discoverCallback,
    //   1,
    //   newSelectedTerms,
    //   getQueryParamValue()
    // );
  };

  return (
    <span>
      <div className="panels">
        <div className="panels_cont">
          <div className="panel-browse panel">
            <div className="browse">
              <div className="browse_panel active" data-name="browse">
                <div className="browse_cont">
                  <div className="browse_left">
                    <div className="prof_box browse">
                      <Contentfilterbox
                        discoverItems={contentFilterBoxItems}
                        selectedTerms={selectedTerms}
                        setSelectedTerms={setSelectedTerms}
                        facetHandler={facetHandler}
                        setDiscoverLoaded={setDiscoverLoaded}
                        isLoading={!discoverLoaded} // Pass loading state
                      />
                    </div>

                    <div className="prof_box grey s hidden">
                      <div className="div-block-376">
                        <img
                          alt=""
                          className="image-127"
                          loading="lazy"
                          src="images/plus-2_1plus-2.png"
                        />
                      </div>
                      <div className="text-block sec_title ex_co amc">
                        Add My Content
                      </div>
                    </div>
                  </div>
                  <div className="br_panel" data-name="top">
                    <div className="text-block-308">
                      {t('Featured Learning Content')}
                    </div>
                    {!discoverLoaded ? (
                      <IBLSpinLoader size={40} containerHeight={'300px'} />
                    ) : (
                      <>
                        <FilterTags
                          selectedTerms={selectedTerms}
                          setSelectedTerms={setSelectedTerms}
                          facetHandler={facetHandler}
                          setDiscoverLoaded={setDiscoverLoaded}
                        />
                        <div className="div-block-430 sl pathway_grid">
                          <Coursesfilterbox
                            discoverItems={discoverItems}
                            discoverLoaded={discoverLoaded}
                          />
                        </div>
                        {parseInt(discoverItems?.total_pages) > 1 && (
                          <ReactPaginate
                            onPageChange={paginate}
                            pageCount={totalPages}
                            previousLabel={'Prev'}
                            nextLabel={'Next'}
                            containerClassName={'pagination'}
                            pageLinkClassName={'page-number'}
                            previousLinkClassName={'page-number'}
                            nextLinkClassName={'page-number'}
                            activeLinkClassName={'active'}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </span>
  );
};

export default BrowseContentView;
