import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getDiscoverItemsAPI, getTenant, getTenants } from './helpers';
import { api, getUserName } from '@iblai/ibl-web-react-common';

function useDiscover() {
  const [discoverItems, setDiscoverItems] = useState([]);
  const [discoverLoaded, setDiscoverLoaded] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const location = useLocation();
  const [selectedTerms, setSelectedTerms] = useState(() => {
    const tenants = getTenants();
    let _selectedTerms = ['content:courses'];
    if (tenants) {
      _selectedTerms = [
        ..._selectedTerms,
        ...tenants.map((tenant) => `tenant:${tenant}`),
      ];
    }
    return _selectedTerms;
  });

  const queryParam = new URLSearchParams(location.search);
  const search = queryParam.get('q');

  useEffect(() => {
    setDiscoverLoaded(false);
    // getDiscoverItemsAPI(discoverCallback, 1, selectedTerms, search);
    const requestData = {
      limit: 12,
    };
    if (page > 1) {
      requestData['offset'] = page * 12;
    }
    if (search) {
      requestData['query'] = search;
    }
    setDiscoverLoaded(false);
    const username = getUserName();
    if (username) {
      requestData['username'] = username;
      api.ibldmsearch.searchPersonalizedCatalog(
        requestData,
        (_data) => {
          discoverCallback(extractCourses(_data.results));
        },
        () => setDiscoverLoaded(true)
      );
    } else {
      api.ibldmsearch.searchCatalog(
        requestData,
        (_data) => {
          setTotalPages(_data.total_pages);
          discoverCallback(extractCourses(_data.results));
        },
        () => setDiscoverLoaded(true)
      );
    }
  }, [search, page]);

  const extractCourses = (results) => {
    return results.map((result) => ({ ...result.data, type: result.type }));
  };

  const discoverCallback = (data) => {
    setDiscoverItems(data);
    setDiscoverLoaded(true);
  };
  return [
    discoverItems,
    discoverLoaded,
    setDiscoverItems,
    setDiscoverLoaded,
    selectedTerms,
    setSelectedTerms,
    totalPages,
    setTotalPages,
  ];
}

export default useDiscover;
